import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Contact extends Component {
  render() {
    return (      
      <div id="#contact" className="contact wrap-box-all" name="contact">
        <a name="contact"></a>
        <Container>
          <div className="title-all text-center">
            Contact Us
            <p className="span2">Start Saving Your Time and Money</p>
          </div>
        </Container>
        <Container>
          <div className="pt-5 d-flex mw-1000 justify-content-center align-content-center wrap-contact">
            <div className="contact-email contact-box">
              <LazyLoadImage src={require("../../../static/image/icon_email.png")} className="img-fluid content-mobile1 mb-5" alt={"icon-email"} />
              <p>Send us email for any kind of inquiry</p>
              <a href="mail-to:admin@salonmanager.com" className="email">admin@salonmanager.com</a>
            </div>
            <div className="contact-tel contact-box">
              <LazyLoadImage src={require("../../../static/image/icon_tel.png")} className="img-fluid content-mobile1 mb-4" alt={"icon-tel"} />
              <p>Call us for immediate support on this number</p>
              <a href="tel:+14085104196" className="email">(408) 510-4196</a>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Contact;
