import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import imageVideo from '../../../static/image/video_image.jpg'
// import VideoLists from './video/video-list';
import {MyVerticallyCenteredModal} from './modal';
import btnPlay from '../../../static/image/btn-play.png';
import plus from '../../../static/image/plus.svg';

const VideoList = () => {
  const [modalShow, setModalShow] = useState(false);
  const videos = [
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
    {image: imageVideo, name: "Getting start"},
  ];

  return (
    <div className="videos wrap-box-all bg-white" name="tutorials">
      <Container>
        <div className="title-all text-center">
          Tutorials
          <p className="span2">Training Videos</p>
        </div>

        <Row className="pt-5">
          {videos.map((item, index) => (
            <Col className="col-12 col-lg-6 col-sm-6" key={index}>
              <div className="box-video mr-3x">
                <div className="video-image">
                  <img src={item.image} alt="video" className="img-fluid"/>
                  <img src={btnPlay} alt="btn-play"  className="video-btn-modal" onClick={() => setModalShow(true)}/>
                </div>
                
                <div className="video-title text-center py-4">
                  {item.name}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <Button className="btn-more-video f-poppins">
            <span><img src={plus} alt="plus" width="12px"/></span>Show More
          </Button>
        </div>
      </Container>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        link={'testLink'}
      />
      {/* <div id="demo" className="carousel slide carousel-fade" data-ride="carousel">
        <div className="carousel-inner spacer-50 d-none d-md-block d-lg-block d-xl-block">
          <div className="carousel-item active hero-image">
            <div className="carousel-caption element-animation">
              <center>
                <span style={{ fontFamily: "MontserratAlternates-Light", color: '#ccc', fontSize: 12 }}>Square is a trademark of Square, Inc.</span>
              </center>
              <video controls className="hero-image img-fluid"  >
                <source src="https://cdn.salonmanager.com/videos/salon-manager.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default VideoList;