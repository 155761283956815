import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-show-video"
      dialogClassName="modal-width"
    >
      <Modal.Body className="p-0">
        <video controls className="img-fluid"  style={{width: '100%'}}>
          <source src="https://cdn.salonmanager.com/videos/salon-manager.mp4" type="video/mp4"></source>
        </video>
      </Modal.Body>
    </Modal>
  );
}