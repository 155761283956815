import React, { Component } from 'react';
import { Image, Button } from "react-bootstrap";
import NavBar from "../NavBar";
import iconSquare from '../../../static/image/play-solid.svg';
import slide from '../../../static/image/slide.jpg';
import { config } from '../../../helper/get_config';
import {MyVerticallyCenteredModal} from './modal';

const HeaderSub = ({routeName}) => {

    const [modalShow, setModalShow] = React.useState(false);
    return (
      <div>
        <NavBar routeName={routeName} bg={true} />
        {/* // se update lai phan text tren banner */}
        <section className="banner position-relative">
          <img src={slide} className="img-fluid" alt="slide" />
          <div className="box-text-header position-absolute">
            <h2 className="text-center text-white animated banner-text mobile pt-200 f-poppins">
              <p>A CLASSY APP COLLECTION FOR</p>
              <p>YOUR BUSY & HIGH-END SALONS</p>
            </h2>
            <h5 className="text-center py-40 mb-5 text-white animated banner-text1 mobile" style={{ fontWeight: '100', fontSize: 22 }}>
              <span>Franchised salons, please contact us for white labeling</span>
            </h5>
            <div className="mx-4 wrap-btn text-left">
              <a href={config.DOWN_APP} className="mx-4" style={{  display: 'inline-block' }}>
                <button className="btn-download bg-transparent f-poppins">Download App</button>
              </a>
              <a className="ml-2 btn-play pointer-event" onClick={() => setModalShow(true)}>
                <Image src={iconSquare}  style={{ width: 20, fill: 'white' }}/>
              </a>
            </div>
          </div>
        </section>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          link={'testLink'}
        />

        {/* <div id="demo" className="carousel slide carousel-fade" data-ride="carousel">
          <div className="carousel-inner spacer-50 d-none d-md-block d-lg-block d-xl-block">
            <div className="carousel-item active hero-image">
              <div className="carousel-caption element-animation">
                <center>
                  <span style={{ fontFamily: "MontserratAlternates-Light", color: '#ccc', fontSize: 12 }}>Square is a trademark of Square, Inc.</span>
                </center>
                <video controls className="hero-image img-fluid"  >
                  <source src="https://cdn.salonmanager.com/videos/salon-manager.mp4" type="video/mp4"></source>
                </video>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
}

export default HeaderSub;