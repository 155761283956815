import React, { Component } from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import calender from '../../../static/image/calendar-alt.svg';

class NewsList extends Component {
  render() {
    return (      
      <div className="news wrap-box-all" name="news">
        <Container>
          <div className="title-all text-center">
            Our News
            <p className="span2">Our Lasted News</p>
          </div>
        </Container>
        <div className="justify-content-center">
          <div className="container pt-5 pb-5">
            <Row>
              <Col className="col-12 col-lg-4 col-sm-4">
                <div className="wrap-news">
                  <LazyLoadImage src={require("../../../static/image/news.png")} className="img-fluid content-mobile1 mb-4 bd-r" alt={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"} />
                  <div className="date-news d-flex align-items-center mb-1">
                    <Image src={calender} alt="icon-calendar"  className="icon-news" />20 August 2021
                  </div>
                  <h3 className="content-mobile title-news f-poppins">
                    <a href="" name="">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                  </h3>
                  <p className="content-news cut-text cut-3 my-4">Keeping a track of employees’ working days is often difficult. With Salon Manager app, employees can schedule themselves easilyfrom their employee app and you can organize employees’ schedule each day.</p>
                  <a href="xx" className="more-news f-poppins">Read More</a>
                </div>
              </Col>
              <Col className="col-12 col-lg-4 col-sm-4">
                <div className="wrap-news">
                  <LazyLoadImage src={require("../../../static/image/news.png")} className="img-fluid content-mobile1 mb-4 bd-r" alt={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"} />
                  <div className="date-news d-flex align-items-center mb-1">
                    <Image src={calender} alt="icon-calendar"  className="icon-news" />20 August 2021
                  </div>
                  <h3 className="content-mobile title-news f-poppins">
                    <a href="" name="">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                  </h3>
                  <p className="content-news cut-text cut-3 my-4">Keeping a track of employees’ working days is often difficult. With Salon Manager app, employees can schedule themselves easilyfrom their employee app and you can organize employees’ schedule each day.</p>
                  <a href="xx" className="more-news f-poppins">Read More</a>
                </div>
              </Col>
              <Col className="col-12 col-lg-4 col-sm-4">
                <div className="wrap-news">
                  <LazyLoadImage src={require("../../../static/image/news.png")} className="img-fluid content-mobile1 mb-4 bd-r" alt={"Lorem ipsum dolor sit amet, consectetur adipiscing elit"} />
                  <div className="date-news d-flex align-items-center mb-1">
                    <Image src={calender} alt="icon-calendar"  className="icon-news" />20 August 2021
                  </div>
                  <h3 className="content-mobile title-news f-poppins">
                    <a href="" name="">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                  </h3>
                  <p className="content-news cut-text cut-3 my-4">Keeping a track of employees’ working days is often difficult. With Salon Manager app, employees can schedule themselves easilyfrom their employee app and you can organize employees’ schedule each day.</p>
                  <a href="xx" className="more-news f-poppins">Read More</a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsList;
