import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';

class AboutList extends Component {
  render() {
    return (
      <div className="about bg-white wrap-box-all">
        <Container>
          <div className="about-title-p text-center mb-5 title-all f-lato-bold">
            Getting Started
            <p className="f-poppins"><span className="span2">Get started in</span> 3 easy steps</p>
          </div>
          <div className="d-flex justify-content-between mw-800 my-80 f-lato-bold wrap-about">
            <div className="about-box">
              <div className="about-num mb-4 f-poppins">1</div>
              <div className="about-text">Download Salon Manager app from the Apple App Store</div>
            </div>
            <div className="about-box">
              <div className="about-num mb-4 f-poppins">2</div>
              <div className="about-text">Setup devices and update your configuration settings in minutes</div>
            </div>
            <div className="about-box">
              <div className="about-num mb-4 f-poppins">3</div>
              <div className="about-text">Let Salon Manager help you maximize your revenue automatically</div>
            </div>
          </div>
          <div className="text-center mt-5">
            <Button className="btn-about f-poppins"> <a href="#contact">? Need Help? Contact Us</a></Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default AboutList;
